import api from './api';

const getEstudiantes = async () => {
    try {
        const response = await api.get(`/student`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudiantesCompletos = async () => {
    try {
        const response = await api.get(`/student/All`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudiantesCumpleanios = async () => {
    try {
        const response = await api.get(`/student/birthdates`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudiantesNuevos = async () => {
    try {
        const response = await api.get(`/student/newStudents`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getMatricula = async (enrollmentId) => {
    try {
        const response = await api.get(`/student/enrollment-item/${enrollmentId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getParents = async (studentId) => {
    try {
        const response = await api.get(`/student/parents?studentId=${studentId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateMatricula = async (enrollmentData) => {
    try {
        const response = await api.put('/student/enrollment', enrollmentData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const removeMatricula = async (enrollmentId) => {
    try {
        const response = await api.delete(`/student/enrollment/${enrollmentId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudiantesPorAnio = async (studentId,journeyId, schoolYearId, isEnrollment) => {
    try {
        const response = await api.get(`/student/enrollment?studentId=${studentId}&journeyId=${journeyId}&schoolYearId=${schoolYearId}&isEnrollment=${isEnrollment}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudiantesPorCurso = async (studentId,journeyId, schoolYearId, educationLevelId, courseId) => {
    try {
        const response = await api.get(`/student/enrollment/list?studentId=${studentId}&journeyId=${journeyId}&schoolYearId=${schoolYearId}&educationLevelId=${educationLevelId}&courseId=${courseId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteNota = async (studentId,courseId,schoolSubjectId, schoolYearId, academicPeriodId,recuperation) => {
    try {
        const response = await api.get(`/student/notes?studentId=${studentId}&courseId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}&recuperation=${recuperation}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteNotaDimension = async (studentId,courseId,schoolSubjectId, schoolYearId, academicPeriodId,recuperation) => {
    try {
        const response = await api.get(`/student/dimension-notes?studentId=${studentId}&courseId=${courseId}&schoolSubjectId=${schoolSubjectId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}&recuperation=${recuperation}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteAsistencia = async (studentId,courseId,schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`/student/attendance?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteAsistenciaJeru = async (studentId,courseId,schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`/student/attendance-jeru?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteIncidencia = async (studentId,courseId,schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`/student/incidences?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteBoletin = async (studentId,courseId,schoolYearId, academicPeriodId, searchByPeriod) => {
    try {
        const response = await api.get(`/student/boletin?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}&searchByPeriod=${searchByPeriod}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


const getEstudianteBoletinIntermedio = async (studentId,courseId,schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`/student/boletin-intermedio?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteBoletinValorativo = async (studentId,courseId,schoolYearId, academicPeriodId, isRecuperation) => {
    try {
        const response = await api.get(`/report/boletin?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}&isRecuperation=${isRecuperation}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getRankingValorativo = async (amount,courseId,schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`/report/ranking?amount=${amount}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteBoletinDimension = async (studentId,courseId,schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`/report/boletin-dimension?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteToNivelar = async (studentId,courseId,schoolYearId, academicPeriodId) => {
    try {
        const response = await api.get(`/report/nivelation-students?studentId=${studentId}&courseId=${courseId}&schoolYearId=${schoolYearId}&academicPeriodId=${academicPeriodId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteHorario = async (courseId, schoolYearId) => {
    try {
        const response = await api.get(`/student/schedule?courseId=${courseId}&schoolYearId=${schoolYearId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudiantesPorUsuario = async (userId) => {
    try {
        const response = await api.get(`/student/generalInfo`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudiantesByParents = async () => {
    try {
        const response = await api.get(`/student/by-parent`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const matricularEstudiante = async (studentData) => {
    try {
        const response = await api.post(`/student/enrollment`, studentData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const createEstudiantes = async (studentData) => {
    try {
        const response = await api.post('/registration', studentData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const updateEstudiantes = async (studentData) => {
    try {
        const response = await api.put('/registration', studentData);
        return response.data;
    } catch (error) {
        throw error;
    }
};


const getEstudiante = async (identification) => {
    try {
        const response = await api.get(`/registration?id=${identification}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getEstudianteAllInformation = async () => {
    try {
        const response = await api.get(`/registration/all`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const subirImagenEstudiantes = async (image, studentId) => {
    try {
        const formDataToSend = new FormData();
        formDataToSend.append('profilePicture', image);   
        formDataToSend.append('studentId', studentId);   
        const response = await api.post('/student/image-upload', formDataToSend,{
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    getEstudiantesPorAnio,
    getEstudiantesPorCurso,
    getEstudiantes,
    getEstudiantesCompletos,
    getEstudiantesNuevos,
    getEstudiantesPorUsuario,
    getEstudiantesByParents,
    getEstudianteNota,
    getEstudianteNotaDimension,
    getEstudianteAsistencia,
    getEstudianteAsistenciaJeru,
    getEstudianteIncidencia,
    getEstudianteHorario,
    getEstudiantesCumpleanios,
    getEstudianteBoletin,
    getEstudianteBoletinIntermedio,
    getEstudianteBoletinValorativo,
    getEstudianteBoletinDimension,
    getEstudianteToNivelar,
    getRankingValorativo,
    getParents,
    matricularEstudiante,
    createEstudiantes,
    subirImagenEstudiantes,
    getEstudiante,
    getEstudianteAllInformation,
    updateEstudiantes,
    getMatricula,
    updateMatricula,
    removeMatricula
};