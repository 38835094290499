
import { useEffect, useRef, useState } from "react";
import HeaderActa from "../../../components/headerActas";
import FooterActa from "../../../components/FooterActa";
import moment from "moment";
import utils from "../../../utils/generalFunctions";
import types from "../../../services/types";
import estudiantesService from "../../../services/estudiantesService";


const RepoPreventivoActa = ({ reporte }) => {
    const filteReference = useRef(null);
    const [showLoading, setShowLoading] = useState(false);
    const [process, setProcess] = useState([]);
    const [parent, setParent] = useState([]);
    const [todayDay, setTodayDay] = useState(moment());

    const cargarProcesos =  () => {
        const processArray = [];
        reporte.schoolSubjects.forEach(subject =>{
            subject.evaluationProcess.forEach(pro =>{
                if(!processArray.find(p=> 
                    p.trim().includes(pro.evaluationProcess.trim()) || 
                    pro.evaluationProcess.trim().includes(p.trim()) ))
                {
                    processArray.push(pro.evaluationProcess);
                }
            });
        });
        setProcess(processArray);
    }

    const cargarPadres = async (studentId) => {
        try {
            const parents = await estudiantesService.getParents(studentId);              
            if(parents && parents.length>0){
                let main = parents[0];
                parents.forEach(p=>{
                    if(p.isMainParent){
                        main=p;
                    }
                });
                setParent(main);
            }            
        } catch (error) {
             console.error("Error al obtener información de padres:", error);
        }
      }

    //getParents

    useEffect(()=>{
        cargarProcesos();
        cargarPadres(reporte.student.studentId);
    },[]);

    const getMes = (mes) =>{
        switch(mes){
            case "1":return "Enero";
            case "2":return "Febrero";
            case "3":return "Marzo";
            case "4":return "Abril";
            case "5":return "Mayo";
            case "6":return "Junio";
            case "7":return "Julio";
            case "8":return "Agosto";
            case "9":return "Septiembre";
            case "10":return "Octubre";
            case "11":return "Noviembre";
            case "12":return "Diciembre";
            default:return "Enero";
        }
    }
   
    return (
        <>
          {!showLoading && reporte && (

            <>
                <div className="col-md-3 mb-2 non-printable">
                        <label htmlFor="txtAsistenciaDate" className="form-label">Fecha Informe: </label>
                        <input
                            id="txtAsistenciaDate"
                            type="date"
                            className="form-control "
                            value={todayDay.format("yyyy-MM-DD")}
                            onChange={(e) => setTodayDay(moment(e.target.value))}/>
                </div>
                <div class="card acta-container">
                
                    {/* <!-- Cabezera --> */}
                    <div className="card-body">
                    
                        <HeaderActa />
                        {/* <!-- Ficha de Estudiante --> */}
                        <section class="acta-atencion">
                            <h2 class="acta-atencion-titulo">ACTA DE ATENCIÓN CORTE PREVENTIVO</h2>
                            <p>En la ciudad de Barranquilla, siendo los {todayDay.format("DD \\d\\í\\a\\s \\d\\e\\l \\m\\e\\s \\d\\e ")} {getMes(String(todayDay.month()+1))} {todayDay.format(" \\d\\e YYYY")}, se cita al señor(a) <strong>{parent?.fullName} con {parent?.identificationTypeName} {parent?.identification}</strong> de BARRANQUILLA, con el fin de entregar INFORME PREVENTIVO DEL <strong> {reporte.student.periodName.toUpperCase()} ACADÉMICO</strong> del estudiante <strong>{reporte.student.studentFullName?.toUpperCase()}</strong> matriculado en el grado <strong>{reporte.student.grade}</strong>, quien ha mostrado las siguientes dificultades en distintos aspectos de su formación en las asignaturas o áreas que se detallan a continuación:</p>
                        </section>
                        <section class="incidencia-info">
                            <table class="table-corte-preventivo">
                                <thead>
                                    <tr>
                                        <th>Asignaturas</th>
                                        {process?.map((pro, index) => (
                                        <th>{index+1}. {pro.substring(0,4)}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {reporte.schoolSubjects.filter(s=>s.evaluationProcess.filter(e=>e.evaluativeScale).length > 0)?.map((subject, index) => (
                                        <tr>
                                            <td>{subject.schoolSubjectName}</td>
                                            {subject.evaluationProcess?.map((pro, index) => (
                                                <td>{pro.evaluativeScale??""}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                        <section class="incidencia-info">
                            <table class="corte-preventivo-lista">
                            <thead>
                                <tr>
                                <th>Procesos</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                    <td>CORTE PREVENTIVO</td>
                                    </tr>
                                    {process?.map((pro, index) => (
                                        <tr>
                                            <td>{index+1}. {pro}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                        <br />
                        <section class="conclusion-compromisos">
                            <p>En consecuencia, es necesario encausar acciones que conlleven a mejorar los aspectos relacionados como faltas o dificultades a nivel académico y asumir los roles que como Institución y familia corresponden para evitar la reprobación de las asignaturas/áreas del plan de estudios en el I periodo del año lectivo.</p>
                            <p>La familia, representada por el acudiente, padre o madre, se compromete a:</p>
                            <ol>
                            <li>Hacer seguimiento y apoyo al trabajo del estudiante en casa, para que pueda cumplir con sus compromisos y afianzar lo aprendido en el aula de clases.</li>
                            <li>Intensificar la atención y el seguimiento en el desempeño académico en las asignaturas o áreas, en las que el estudiante presenta dificultades hasta la fecha.</li>
                            </ol>
                            <p>En virtud de lo anterior, y para constancia se firma por quienes intervinieron:</p>
                        </section>
                        <br />
                        <FooterActa 
                            creadorId={reporte.student.groupDirectorId??0}
                            reportTypeId={types.reportTypes.ACTA}></FooterActa>
                    </div>

                </div>

                {/* <FooterActa></FooterActa> */}

                <br />
                    <button className="btn btn-primary center display-block" onClick={() => window.print()}>Imprimir</button>
                <br />

            </>
          )}
        </>
    );
  };
  
  export default RepoPreventivoActa;