import { useEffect, useState } from "react";
import procesoService from "../../services/procesoService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "../Loading";
import DataTable from "react-data-table-component";
import definicionNotaServices from "../../services/definicionNotaServices";
import moment from "moment";

const FormDefinicionNotas = (props) => {
    const { definitionNote, evaluationProcessId, paramToSearch , onClose, ReadOnly }=props;
    const initData={
        evaluationProcessId:evaluationProcessId,
        notesAmount:0,
        details:[],
        description:"",
        evaluationDate:""
    };

    const [formData, setFormData] = useState(initData);
    const [showLoading, setShowLoading] = useState(true);
    const [academicAreasList, setAcademicAreasList] = useState([]);
    const [academicAreas, setAcademicAreas] = useState([]);    
    const [procesos, setProcesos] = useState([]);    
    let areasList = [];
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const cargarProcesos = async () => {
        setShowLoading(true); // Cambia el estado a false una vez que los datos se carguen
        try {
            if(definitionNote===null){
                console.log(paramToSearch);
                const process = await procesoService.getProcesosToNotas(paramToSearch.courseId, paramToSearch.schoolSubjectId, paramToSearch.schoolYearId, paramToSearch.periodId, paramToSearch.evaluationTypeId);
                const procesosDB = process.filter(p=> p.isRecuperation === (paramToSearch.isRecuperation?1:0));
                if(procesosDB){
                    setProcesos(procesosDB);
                }else{
                    setProcesos([]);
                }
            }else{
                setFormData(prevState => ({
                    ...prevState,...definitionNote
                }));
            }
        } catch (error) {
          console.error("Error al obtener procesos:", error);
        }
        setShowLoading(false);// Cambia el estado a false una vez que los datos se carguen
    }

    useEffect(() => {    
        cargarProcesos();
      }, []);
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validación básica
        const requiredFields =(definitionNote != null)?['evaluationDate','description']:['evaluationProcessId'];
        for (let field of requiredFields) {
            if (!formData[field]) {
                toast.error((definitionNote != null)?"Por favor, llene los campos obligatorios.":"Por favor, seleccione un proceso evaluativo", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                return;
            }
        }

        if(definitionNote===null){
            if(formData.details.length>0){
                for (let item of formData.details) {
                    if (item.description.trim() === "" || item.date.trim() ==="") {
                        toast.error("Por favor, llene los campos obligatorios.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        return;
                    }
                }
            }else{
                toast.error("Por favor, adicione por lo menos una definición de nota.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
        }

        if(!ReadOnly)
        {
          setShowLoading(true);
          try {
              let notesDefinitionId = 0;
              if (definitionNote != null) {
                const defNota={
                    notesDefinitionId:definitionNote.notesDefinitionId,
                    evaluationProcessId:definitionNote.evaluationProcessId,
                    description:formData.description,
                    evaluationDate:formData.evaluationDate,
                };
                const notas = await definicionNotaServices.updateDefinicionDeNota(defNota);
                if(notas && notas.notesDefinitionId>0){
                    notesDefinitionId = notas.notesDefinitionId;
                }
              } else{
                for (let item of formData.details) {
                    const defNota={
                        evaluationProcessId:formData.evaluationProcessId,
                        description:item.description,
                        evaluationDate:item.date,
                    };
                    const notas = await definicionNotaServices.createDefinicionDeNota(defNota);
                    if(notas && notas.notesDefinitionId>0){
                        notesDefinitionId = notas.notesDefinitionId;
                    }
                }
                  //const response = await asignaturaService.createAsignatura(formData);
                  //asignaturaId = response.schoolSubjectId;
              }
              if (notesDefinitionId) {
                  Swal.fire(
                      "Felicidades!",
                      "Información guardada con éxito!",
                      "success",
                      );
                    if (onClose) onClose();
                    setFormData(initData);
              } else {
                  Swal.fire(
                      "Ha ocurrido un error",
                      "Por favor intentelo mas tarde",
                      "error"
                    );
              }
          } catch (error) {
              console.error('Error al enviar el formulario:', error);
              Swal.fire(
                  "Ha ocurrido un error",
                  "Por favor intentelo mas tarde",
                  "error"
                );
          }
        }
        setShowLoading(false);
    };

    const customStyles = {
        rows: {
          style: {
            minHeight: "30px",
            backgroundColor: "#ffffff", // Un azul claro basado en el color principal
            "&:hover": {
              backgroundColor: "#e6ecf5", // Un azul ligeramente más oscuro para el hover
            },
          },
        },
        headCells: {
          style: {
            backgroundColor: "#ddeafc", // Color azul principal para el fondo de las celdas del encabezado
            color: "#3f80ea", // Texto blanco para las celdas del encabezado
            fontSize: "14px",
          },
        },
        cells: {
          style: {
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "#e0eaf5", // Un azul aún más oscuro para el hover de las celdas
            },
            fontSize: "14px",
            padding: "8px 16px",
          },
        },
        pagination: {
          style: {
            backgroundColor: "#ffffff", // Un azul ligeramente más oscuro para la paginación
            borderTop: "1px solid #c8d4e8", // Un azul desaturado para la línea superior de la paginación
            fontSize: "14px",
            padding: "8px",
          },
        },
    };

    const columns = [
        { name: "Descripción", selector: "description",  cell: (row, index) => (
            <>
            <div className="row w-100">
                <div className="col-md-12 w-100">
                        <input
                            type="text"
                            className="form-control w-100"
                            value={row.description}
                            onChange={(e) => handleInputDetailChange(e, index,'description')}/>
                </div>
            </div>
            </>), 
        },
        { name: "Fecha", selector: "date",  grow:0.5, cell: (row, index) => (
            <>
                 <div className="row ">
                    <div className="col-md-12">
                            <input
                                type="date"
                                className="form-control "
                                value={row.date}
                                onChange={(e) => handleInputDetailChange(e, index,'date')}/>
                    </div>
                </div>
            </>), 
        },
        { name: "", selector: "evaluationProcessId" , grow:0.5, cell: (row, index) => (
            <i className="fas fa-xmark clear-button-searcher" aria-hidden="true" onClick={() => handleRemoveNoteDefinition(index)}></i>
        ) },
    ];

    const handleRemoveNoteDefinition = (index) => {
        const removedElement = formData.details[index];
        const updatedDetails = formData.details.filter((_, i) => i !== index);
        setFormData({ ...formData, details: updatedDetails });
    };

    const handleInputDetailChange = (e, index, field) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            details: prevFormData.details.map((detail, i) =>
                i === index ? { ...detail, [field]: e.target.value } : detail
            )
        }));
    };

    const AddDefinitionNotes = (e) => {
        e.preventDefault();
        let details = [];
        for (let i = 0; i < formData.notesAmount; i++) {
            const newDetail = {
                description: "",
                date: "",
            };
            details = [...details,newDetail ];
        }
        setFormData({
            ...formData,
            details: [...formData.details, ...details]
        });
    }

    return (
         <>
        {showLoading && <Loading />}

        {!showLoading && (
            <div className="container-fluid mt-4">
            <p className="text-muted">Por favor, llena todos los campos para registrar la nueva definicion de nota.</p>
            
            <form onSubmit={handleSubmit} className="mt-3">

                {!definitionNote && (
                    <>
                        <div className="row">
                            
                            <div className="col-md-6 mb-3">
                                <label htmlFor="evaluationProcessId" className="form-label">Proceso academico: </label>
                                <select className="form-control form-select" 
                                        id="evaluationProcessId"
                                        name="evaluationProcessId" 
                                        value={formData.evaluationProcessId} 
                                        ReadOnly={(evaluationProcessId>0)}
                                        onChange={handleChange} >
                                            <option value="0" >Seleccione</option>
                                            {procesos.map((process) => (
                                                <option key={process.evaluationProcessId} value={process.evaluationProcessId} >
                                                    {process.description} {(process.percentage!==null)?(" - "+process.percentage):""}
                                                </option>
                                            ))}
                                </select>
                            </div>

                            <div className="col-md-3 mb-3">
                                <label htmlFor="notesAmount" className="form-label">Cantidad de notas:</label>
                                <select className="form-control form-select" id="notesAmount" name="notesAmount" 
                                    value={formData.notesAmount}  onChange={handleChange}>
                                    <option value="0" >Seleccione</option>
                                    <option value="1" >1</option>
                                    <option value="2" >2</option>
                                    <option value="3" >3</option>
                                    <option value="4" >4</option>
                                    <option value="5" >5</option>
                                    <option value="6" >6</option>
                                    <option value="7" >7</option>
                                    <option value="8" >8</option>
                                    <option value="9" >9</option>
                                    <option value="10" >10</option>
                                </select>
                            </div>

                            <div className='col-md-2 margin-top-45'>
                                <button className="btn btn-primary" onClick={AddDefinitionNotes}>Añadir</button>
                            </div>

                        </div>

                        <DataTable
                            columns={columns}
                            data={formData.details}
                            customStyles={customStyles}
                            className="students-datatable shadow-sm rounded"
                            noDataComponent=""   
                            noHeader
                            dense
                            highlightOnHover
                            pointerOnHover
                        />
                    </>
                )}

                {definitionNote && (
                    <>
                        <div className="row">
                            
                            <div className="col-md-6 mb-3">
                                <label htmlFor="evaluationProcessId" className="form-label">Descripción: </label>
                                <input
                                    type="text"
                                    name="description"
                                    className="form-control"
                                    value={formData.description}
                                    onChange={handleChange}/>
                            </div>

                            <div className="col-md-3 mb-3">
                                <label htmlFor="evaluationDate" className="form-label">Fecha:</label>
                                <input
                                    type="date"
                                    name="evaluationDate"
                                    className="form-control"
                                    value={moment(formData.evaluationDate).format("yyyy-MM-DD")}
                                    onChange={handleChange}/>
                            </div>
                        </div>
                    </>
                )}
                
                {!ReadOnly  && (
                    <div className='row'>
                        <div className='col-md-1 center padding-top-20 padding-bottom-20'>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </div>
                )}
            </form>
            </div>
        )}
        </>
    );
}

export default FormDefinicionNotas;
